import { AfterViewInit, Component, Inject, Input, OnDestroy } from '@angular/core';
import { AuthService } from '@injectables/services/auth/auth.service';
import { Observable, Subject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { DEVICE_TYPE, DeviceTypeService } from '@injectables/services/device-type.service';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import {
	isNewerVersionAvailable,
	isOwnerOfCompany,
	selectRouterState,
	selectTheme,
	selectUserId,
} from '@store/selectors/app.selectors';
import { WINDOW } from '@craftnote/shared-utils';
import { ProjectHelperService } from '@injectables/services/project-helper.service';
import { AppTheme } from '@store/reducers/app.reducer';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { currentUserProfileLimitsSelector } from '@store/selectors/profile-limits.selectors';
import { TrackingService } from '@injectables/services/tracking.service';
import { SubscriptionService } from '@injectables/services/subscription/subscription.service';
import { UpgradesPaywallheaderStartproSelectedEventBuilder } from '@generated/events/UpgradesEvents.generated';
import { Environment, ENVIRONMENT } from '@shared/tokens/environment';
import { LoopedInService } from '@injectables/services/looped-in.service';
import { ProfileLimitKey } from '@shared/models/profile-limit-key.enum';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnDestroy, AfterViewInit {
	theme$: Observable<AppTheme> = this.store.select(selectTheme);
	Theme = AppTheme;
	@Input() authenticated: boolean;
	@Input() publicRoute: boolean;

	ProfileLimitKey = ProfileLimitKey;
	DEVICE_TYPE = DEVICE_TYPE;
	cachedDeviceType: DEVICE_TYPE;
	userId$ = this.store.select(selectUserId).pipe(filter((userId) => !!userId));
	isPdfViewerRoute$ = this.store.select(selectRouterState).pipe(
		filter((routerState) => !!routerState),
		map((routerState) => routerState.state.url.startsWith('/pdf-viewer')),
	);
	isCurrentUserBasic$ = this.store
		.select(currentUserProfileLimitsSelector)
		.pipe(
			map(
				(currentUserProfileLimits) => currentUserProfileLimits.limits?.taskTimeDashboard === false,
			),
		);

	private destroy$ = new Subject();

	isLatestVersionAvailable$ = this.store.select(isNewerVersionAvailable);

	constructor(
		readonly router: Router,
		private readonly activatedRoute: ActivatedRoute,
		private readonly authService: AuthService,
		private readonly remoteConfig: RemoteConfig,
		private readonly deviceService: DeviceTypeService,
		private readonly store: Store<AppState>,
		@Inject(WINDOW) private readonly windowRef: Window,
		private readonly projectHelperService: ProjectHelperService,
		private readonly trackingService: TrackingService,
		private readonly subscriptionService: SubscriptionService,
		private readonly loopedInService: LoopedInService,
		@Inject(ENVIRONMENT) public readonly environmentRef: Environment,
	) {}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	ngAfterViewInit(): void {
		this.loopedInService.initializeLoopedIn();
	}

	get deviceType(): DEVICE_TYPE {
		if (this.cachedDeviceType === undefined) {
			this.cachedDeviceType = this.deviceService.getDeviceType();
		}
		return this.cachedDeviceType;
	}

	get isPaywallHeaderEnabled(): boolean {
		return !!this.remoteConfig.getValue('feature_paywall_header');
	}

	updateVersion(): void {
		this.windowRef.location.reload();
	}

	logout(): void {
		void this.authService.signOut();
	}

	async onClickPaywallHeaderButton(): Promise<void> {
		const isOwner = await this.store.select(isOwnerOfCompany).pipe(take(1)).toPromise();
		if (isOwner) {
			const isCurrentUserBasic = await this.isCurrentUserBasic$.pipe(take(1)).toPromise();
			const subscription = await this.subscriptionService
				.getActiveSubscription()
				.pipe(take(1))
				.toPromise();
			const nextNavigation =
				!!subscription && isCurrentUserBasic
					? 'settings/subscription'
					: 'settings/subscription/products';
			await this.trackingService.trackEvent(
				new UpgradesPaywallheaderStartproSelectedEventBuilder({}),
			);
			await this.router.navigateByUrl(nextNavigation);
		}
	}

	goToHome(): void {
		this.projectHelperService.goToHome();
	}

	async openPaywallHeaderDialog(): Promise<void> {
		await this.router.navigate([], {
			relativeTo: this.activatedRoute,
			fragment: 'explore-pro',
		});
	}
}

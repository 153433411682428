import { Component, OnInit, signal } from '@angular/core';
import { MemberRole, Project, ProjectType } from 'domain-entities';
import { ProjectAreaStore } from './store/project-area.store';
import { ProjectContext } from '@shared/models/project.type';
import { Store } from '@ngrx/store';
import {
	selectActiveProject,
	selectIsProjectArchiveRoute,
	selectWorkRouteState,
} from '@store/selectors/route.selectors';
import { map, take } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { selectProjectStatus, selectUserId, selectUserRole } from '@store/selectors/app.selectors';
import { updateLastActiveFactory } from '@shared/firebase/project/project-update.functions';
import { ProjectService } from '@injectables/services/project/project.service';
import { GroupByOption } from '@work/project-search/types/project-sort.types';
import { selectProject, selectSelectedFolderId } from '@store/selectors/projects.selectors';
import { setSelectedFolderAction } from '@store/actions/project.actions';
import { ProjectListStoreUpstreamData } from '@modules/shared/components/projects-area/components/projects-list/store/project-list.store';
import { toObservable } from '@angular/core/rxjs-interop';
import { selectProjectsWithFailedUploads } from '@store/selectors/file-upload.selectors';

@Component({
	selector: 'app-projects-area',
	templateUrl: './projects-area.component.html',
	styleUrls: ['./projects-area.component.scss'],
	providers: [ProjectAreaStore],
})
export class ProjectsAreaComponent implements OnInit {
	filteredProjects = signal<Project[]>([]);
	projects$ = this.componentStore.openFolderProjects$;
	openFolder$ = this.componentStore.openFolder$;
	isArchive$ = this.store.select(selectIsProjectArchiveRoute);
	context$: Observable<ProjectContext> = this.store
		.select(selectWorkRouteState)
		.pipe(
			map((state) =>
				state.workRoute === 'projects' ? ProjectContext.ACTIVE : ProjectContext.ARCHIVE,
			),
		);
	selectedProjectId$ = this.store.select(selectActiveProject);

	GroupByOption = GroupByOption;
	groupByOption = signal<GroupByOption>(GroupByOption.NONE);

	isOnActiveRootLevel$ = combineLatest([this.context$, this.openFolder$]).pipe(
		map(([context, openFolder]) => context === ProjectContext.ACTIVE && !openFolder),
	);

	canShowArchivationNudge$ = combineLatest([
		this.isOnActiveRootLevel$,
		this.store.select(selectUserRole),
	]).pipe(map(([isOnActiveRootLevel, role]) => isOnActiveRootLevel && role === MemberRole.OWNER));

	projectListStoreData$: Observable<ProjectListStoreUpstreamData> = combineLatest([
		toObservable(this.filteredProjects),
		this.componentStore.archivableProjects$,
		this.store.select(selectProjectsWithFailedUploads),
		this.isOnActiveRootLevel$,
		this.canShowArchivationNudge$,
		this.store.select(selectProjectStatus),
		toObservable(this.groupByOption),
	]).pipe(
		map(
			([
				projects,
				archivableProjects,
				projectsWithFailedUploads,
				canShowFailedUploadsNudge,
				canShowArchivationNudge,
				projectStatus,
				groupBy,
			]) => ({
				projects,
				archivableProjects,
				projectsWithFailedUploads,
				canShowFailedUploadsNudge,
				canShowArchivationNudge,
				projectStatus,
				groupBy,
			}),
		),
	);

	ProjectContext = ProjectContext;
	isFilterActive: boolean;

	constructor(
		private readonly componentStore: ProjectAreaStore,
		private readonly store: Store,
		private readonly router: Router,
		private readonly projectService: ProjectService,
	) {}

	ngOnInit(): void {
		this.initOpenFolder();
	}

	async onProjectClick(project: Project, action: 'open' | 'back' = 'open'): Promise<void> {
		// If a project cards back arrow is clicked then the folder is activated but closed
		if (action === 'back') {
			this.componentStore.openFolder(null);
		} else if (project.projectType === ProjectType.FOLDER) {
			this.componentStore.openFolder(project.id);
		}

		const currentProject = await this.store.select(selectActiveProject).pipe(take(1)).toPromise();
		const isArchiveRoute = await this.store
			.select(selectIsProjectArchiveRoute)
			.pipe(take(1))
			.toPromise();
		let isRouterNavigated: boolean;
		if (!currentProject) {
			isRouterNavigated = await this.router.navigate([
				isArchiveRoute ? 'archive' : 'projects',
				project.id,
			]);
		} else {
			/**
			 * This is a slight hack. We are reusing the current url but are replacing
			 * the project id. Unfortunately Angular does not provide an easy way to replace
			 * a specific parameter by name.
			 */
			isRouterNavigated = await this.router.navigate([
				this.router.url.replace(currentProject, project.id),
			]);
		}

		if (isRouterNavigated === false) {
			return;
		}

		const currentUser = await this.store.select(selectUserId).pipe(take(1)).toPromise();
		const updateFunction = updateLastActiveFactory(currentUser);

		await this.projectService.updateProjectTransactional(project.id, updateFunction);
	}

	groupBy(groupByOption: GroupByOption): void {
		this.groupByOption.set(groupByOption);
	}

	/**
	 * When the component is created we are checking ONCE if the currently open project is inside a folder and
	 * if so we are trying to open that folder if by the time we resolved the open project no other folder has been
	 * opened in the meantime.
	 */
	private async initOpenFolder(): Promise<void> {
		const projectIdAtStart = await this.selectedProjectId$.pipe(take(1)).toPromise();
		if (!projectIdAtStart) {
			return;
		}

		const project = await this.store
			.select(selectProject, { projectId: projectIdAtStart })
			.pipe(take(1))
			.toPromise();

		const selectedProjectAfterResolution = await this.selectedProjectId$.pipe(take(1)).toPromise();

		const currentOpenFolder = await this.store
			.select(selectSelectedFolderId)
			.pipe(take(1))
			.toPromise();

		if (
			project?.parentProject &&
			project?.id === selectedProjectAfterResolution &&
			!currentOpenFolder
		) {
			this.store.dispatch(setSelectedFolderAction({ folderId: project?.parentProject }));
		}
	}
}

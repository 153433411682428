import {
	Component,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { Project } from 'domain-entities';
import { Observable } from 'rxjs/internal/Observable';
import { selectAllProjects } from '@store/selectors/projects.selectors';
import { map, shareReplay, tap } from 'rxjs/operators';
import { ProjectSortAndSearchHelper } from '@injectables/services/project-sort-and-search-helper.service';
import { selectActiveProject } from '@store/selectors/route.selectors';
import { ProjectListStore, ProjectListStoreUpstreamData } from './store/project-list.store';
import { Store } from '@ngrx/store';
import { VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { ProjectsListScrollStrategy } from './util/projects-list-scroll-strategy';
import { AppState } from '@store/state/app.state';
import { fromPairs, get } from 'lodash';
import { ProfileSettingService } from '@injectables/services/profile-setting.service';
import { Subject } from 'rxjs';
import { pause } from '@craftnote/shared-utils';
import { ProjectContext } from '@shared/models/project.type';

const scrollFactory = (projectListStore: ProjectListStore, appStore: Store<AppState>) =>
	new ProjectsListScrollStrategy(projectListStore, appStore);

@Component({
	selector: 'app-projects-list',
	templateUrl: './projects-list.component.html',
	styleUrls: ['./projects-list.component.scss'],
	providers: [
		ProjectListStore,
		{
			provide: VIRTUAL_SCROLL_STRATEGY,
			useFactory: scrollFactory,
			deps: [ProjectListStore, Store],
		},
	],
})
export class ProjectsListComponent implements OnChanges {
	@Input() showParentHint = false;
	@Input() storeState: ProjectListStoreUpstreamData;
	@Input() context: ProjectContext;

	@Output() projectClick = new EventEmitter<Project>();

	selectedProjectId$ = this.store.select(selectActiveProject);

	menuOpened$$ = new Subject<boolean>();

	groupedProjects$ = this.projectListStore.projectListItems$.pipe(pause(this.menuOpened$$));

	lastEditedTimestampByProject$: Observable<{ [keys: string]: number }> = this.store
		.select(selectAllProjects)
		.pipe(
			map((projects) => this.projectSortHelper.getInferredLastEditedTimestampByProjectId(projects)),
			shareReplay(),
		);

	isChatMessagesMuted$: Observable<{ [projectId: string]: boolean }> = this.profileSettingService
		.getProfileSettings()
		.pipe(
			map((profileSettings) => get(profileSettings, `mutedPushes.ChatNewMessage.identifiers`, [])),
			map((ids) => ids.map((id) => [id, true])),
			map(fromPairs),
			tap((mutedProjects) => this.projectsListScrollStrategy.setMutedProjects(mutedProjects)),
		);

	constructor(
		private readonly projectSortHelper: ProjectSortAndSearchHelper,
		private readonly store: Store,
		private readonly projectListStore: ProjectListStore,
		private readonly profileSettingService: ProfileSettingService,
		@Inject(VIRTUAL_SCROLL_STRATEGY)
		readonly projectsListScrollStrategy: ProjectsListScrollStrategy,
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['storeState']) {
			this.projectListStore.setUpstreamData(this.storeState);
		}
	}

	getLastEditedTimestampOfProject(projectId: string): Observable<number> {
		return this.lastEditedTimestampByProject$.pipe(map((times) => times[projectId]));
	}

	onProjectClick(project: Project): void {
		this.projectClick.emit(project);
	}

	toggleCollapse(id: string): void {
		this.projectListStore.toggleCollapse(id);
	}

	onMenuOpen($event: boolean): void {
		this.menuOpened$$.next($event);
	}
}
